import React, { useState, useEffect } from "react";
import {BrowserRouter, Route, Routes, Outlet}  from 'react-router-dom';
import Navbar from "./components/Navbar";
import Overview from "./components/Overview";
import Service from "./components/Service";
import Category from "./components/Category";
import Tier from "./components/Tier";
import Breakdown from "./components/Breakdown";
import Login from "./components/Login";
import ServicebyCompany from "./components/ServicebyCompany";
import axios from "axios";
import "./App.css";
import { AppContext } from "./lib/contextLib";
import Catdetails from "./components/Catdetails";
axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  useEffect(() => {
    onLoad();
  }, []);
  
  async function onLoad() {
    try {
      var uname = sessionStorage.getItem("username");
      if(uname !==""){
        userHasAuthenticated(true);
      }
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }
  
    setIsAuthenticating(false);
  }
  return (
    !isAuthenticating && (
    <BrowserRouter>
    <div className="App">
    <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
      <Navbar/>
      <Outlet />
      <Routes>
          <Route index path='/' element={<Login/>} />
          <Route  path='/overview' element={<Overview/>} />
          <Route path='/service' element={<Service/>} />
          <Route path='/servicebycompany' element={<ServicebyCompany/>} />
          <Route path='/category' element={<Category/>} />
          <Route path='/tier' element={<Tier/>} />
          <Route path='/breakdown' element={<Breakdown/>} />
          <Route path='/catdetails' element={<Catdetails/>} />
          <Route path='*' element={<Login/>} />
      </Routes>
      </AppContext.Provider>
    </div>
    </BrowserRouter>
    )
  );
}

export default App;
