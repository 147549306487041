import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Overview.css';
import $ from 'jquery';
import {Link, useNavigate} from "react-router-dom";
const Overview = () => {
    const history = useNavigate();
    const [mrev, setmrev] = useState('');
    const [yrev, setyrev] = useState('');
    const [mgoal, setmgoal] = useState('');
    const [mpercent, setmpercent] = useState('');
    const [ygoal, setygoal] = useState('');
    const [ypercent, setypercent] = useState('');
    const [tieram, settieram] = useState('');
    const [tieray, settieray] = useState('');
    const [tierbm, settierbm] = useState('');
    const [tierby, settierby] = useState('');
    const [tiercm, settiercm] = useState('');
    const [tiercy, settiercy] = useState('');
    const [mrevf, setmrevf] = useState('');
    const [yrevf, setyrevf] = useState('');
    const [mgoalf, setmgoalf] = useState('');
    const [mpercentf, setmpercentf] = useState('');
    const [ygoalf, setygoalf] = useState('');
    const [ypercentf, setypercentf] = useState('');
    useEffect(() => {
        getOverview();
        var uname = sessionStorage.getItem("username");
        var pword = sessionStorage.getItem("password");
        if(uname==="" && pword===""){
          history("/login");
        }
    },[]);
    const getOverview = async () => {
        const data = new FormData();
        data.append("username",sessionStorage.getItem("username"));
        data.append("pwd",sessionStorage.getItem("password"));
        const response = await axios.post('https://crmtemp.quentosity.co.nz/getoverview.php',data);
        console.log(response);
        response.data.forEach(function(r){
            setmrev(r.mrevenue);
            setyrev(r.yrevenue);
            setmgoal(r.mgoal);
            setmpercent(r.mpercent);
            setygoal(r.ygoal);
            setypercent(r.ypercent);
            settieram(r.tieram);
            settieray(r.tieray);
            settierbm(r.tierbm);
            settierby(r.tierby);
            settiercm(r.tiercm);
            settiercy(r.tiercy);
            setmrevf(r.mrevenuef);
            setyrevf(r.yrevenuef);
            setmgoalf(r.mgoalf);
            setmpercentf(r.mpercentf);
            setygoalf(r.ygoalf);
            setypercentf(r.ypercentf);
        });
    }
    $( ".Bar-Yearly" ).each(function() {
        let percent = ypercent;
        if(percent > 100){
          percent = 100;
        }
        $(this).animate({width: percent+'%' }, 2000);
      });
      $( ".Bar-Monthly" ).each(function() {
        let percent = mpercent;
        if(percent > 100){
          percent = 100;
        }
        $(this).animate({width: percent+'%' }, 2000);
      });
      $( ".Bar-Yearly-Forecast" ).each(function() {
        let percent = ypercent;
        if(percent > 100){
          percent = 100;
        }
        $(this).animate({width: percent+'%' }, 2000);
      });
      $( ".Bar-Monthly-Forecast" ).each(function() {
        let percent = mpercent;
        if(percent > 100){
          percent = 100;
        }
        $(this).animate({width: percent+'%' }, 2000);
      });

    return(
        <div className='ui raised' style={{marginTop:'80px',backgroundColor:'#f4f4f4'}}>
                        <div className='bgwrapperwhite' style={{backgroundColor:'#fff',padding:'10px'}}>
                            <div className='revenue'>
                                <div className='tagsrev'>Monthly Revenue</div>
                                <div className='amountrev'>{mrev}</div>
                            </div>
                            <div className='goaltrend'>
                                <div className='uppertext monthlygoal' style={{display:'flex'}}><div className='percentomonthlygoal'>{mpercent}% to goal</div><div className='amounmonthlygoal'>{mgoal}</div></div>
                                <div className='Progress monthlygoal' style={{display:'block'}}>
                                <div className='Bar-Monthly' data-value={mpercent}></div>
                                </div>
                                <div className='lowertext monthlygoal' style={{display:'block'}}><div className='currentmonthly'>{mrev}</div></div>
                            </div>
                            <div className='revenue' style={{marginTop:'20px'}}>
                                <div className='tagsrev'>Yearly Revenue</div>
                                <div className='amountrev'>{yrev}</div>
                            </div>
                            <div className='goaltrend'>
                                <div className='uppertext yearlygoal' style={{display:'flex'}}><div className='percentomonthlygoal'>{ypercent}% to goal</div><div className='amounmonthlygoal'>{ygoal}</div></div>
                                <div className='Progress yearlygoal' style={{display:'block'}}>
                                <div className='Bar-Yearly' data-value={ypercent}></div>
                                </div>
                                <div className='lowertext monthlygoal' style={{display:'block'}}><div className='currentmonthly'>{yrev}</div></div>
                            </div>
                        </div>
                        <div className='bgwrapperbeige' style={{backgroundColor:'#fff',padding:'10px 10px 20px 10px'}}>
                            <div className='revenuebeige'>
                                <div className='tagsrevbeige'>Monthly Forecast Revenue</div>
                                <div className='amountrevbeige'>{mrevf}</div>
                            </div>
                            <div className='goaltrendbeige'>
                                <div className='uppertext monthlygoal' style={{display:'flex'}}><div className='percentomonthlygoal'>{mpercentf}% to goal</div><div className='amounmonthlygoal'>{mgoalf}</div></div>
                                <div className='Progress monthlygoal' style={{display:'block'}}>
                                <div className='Bar-Monthly-Forecast' data-value={mpercentf}></div>
                                </div>
                                <div className='lowertext monthlygoal' style={{display:'block'}}><div className='currentmonthly'>{mrevf}</div></div>
                            </div>
                            <div className='revenuebeige' style={{marginTop:'20px'}}>
                                <div className='tagsrevbeige'>Yearly Forecast Revenue</div>
                                <div className='amountrevbeige'>{yrevf}</div>
                            </div>
                            <div className='goaltrendbeige'>
                                <div className='uppertext yearlygoal' style={{display:'flex'}}><div className='percentomonthlygoal'>{ypercentf}% to goal</div><div className='amounmonthlygoal'>{ygoalf}</div></div>
                                <div className='Progress yearlygoal' style={{display:'block'}}>
                                <div className='Bar-Yearly-Forecast' data-value={ypercentf}></div>
                                </div>
                                <div className='lowertext monthlygoal' style={{display:'block'}}><div className='currentmonthly'>{yrevf}</div></div>
                            </div>
                        </div>
                        <div className='tierwrapperbg container'>
                            <div className='tierwrapper' style={{marginTop:'20px'}}>
                                <div className='tierheader'>Tier A</div>
                                <div className='theader' style={{display:'flex'}}><div >MONTHLY REVENUE</div><div style={{textAlign:'right'}}>YEARLY REVENUE</div></div>
                                <div className='theader' style={{display:'flex'}}><div className='theaderamount'>{tieram}</div><div className='theaderamount' style={{textAlign:'right'}}>{tieray}</div></div>
                            </div>
                            <Link className="tierlink" to="/tier" state={{ tier: "a",'mly':tieram,'yly':tieray}}>View List</Link>
                            <div className='tierwrapper' style={{marginTop:'20px'}}>
                                <div className='tierheader'>Tier B</div>
                                <div className='theader' style={{display:'flex'}}><div>MONTHLY REVENUE</div><div style={{textAlign:'right'}}>YEARLY REVENUE</div></div>
                                <div className='theader' style={{display:'flex'}}><div className='theaderamount'>{tierbm}</div><div className='theaderamount' style={{textAlign:'right'}}>{tierby}</div></div>
                            </div>
                            <Link className="tierlink" to="/tier" state={{ tier: "b",'mly':tierbm,'yly':tierby }}>View List</Link>
                            <div className='tierwrapper' style={{marginTop:'20px'}}>
                                <div className='tierheader'>Tier C</div>
                                <div className='theader' style={{display:'flex'}}><div>MONTHLY REVENUE</div><div style={{textAlign:'right'}}>YEARLY REVENUE</div></div>
                                <div className='theader' style={{display:'flex'}}><div className='theaderamount'>{tiercm}</div><div className='theaderamount' style={{textAlign:'right'}}>{tiercy}</div></div>
                            </div>
                            <Link className="tierlink" to="/tier" state={{ tier: "c",'mly':tiercm,'yly':tiercy }}>View List</Link>
                        </div>
        </div>
    )
}

export default Overview;