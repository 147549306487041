import React , { useState }from "react";
import {Link, useNavigate} from "react-router-dom";
import xlogo from "./x.svg";
import overviewlogo from "./overview.png";
import logoutlogo from "./logout.png";
import executivelogo from "./executive.svg";
import servicelogo from "./service.svg";
import servicebycompanylogo from "./servicebycompany.svg";
import categorylogo from "./category.svg";
import { useAppContext } from "../lib/contextLib";
const Navbar = () => {
    const history = useNavigate();
    const [showmenu,setmenu] = useState(false);
    var uname = sessionStorage.getItem("username");
    const { userHasAuthenticated } = useAppContext();
    function onClick(){
      setmenu(true);
    }
    function closeMenu(){
        setmenu(false);
    }
    function logout(){
        setmenu(false);
        userHasAuthenticated(false);
        sessionStorage.setItem("username","");
        sessionStorage.setItem("password","");
    }
    function logoutx(){
        setmenu(false);
        userHasAuthenticated(false);
        sessionStorage.setItem("username","");
        sessionStorage.setItem("password","");
        history("./login");
    }
    return (
        <div>
                <nav className="navbar navbar-inverse navbar-fixed-top" style={uname!==""?{display:'block'}:{display:'none'}}>
                    <div className="container-fluid">
                        <div  id="myNavbar" style={showmenu?{display:'block'}:{display:'none'}}>
                        <div className="row" style={{height:'100vh',background:'#fff'}}>
                            <div className="col-md-4 col-xs-4 horinav" style={{background:'#fef4ed',height:'100vh'}}>
                                <img src={overviewlogo} alt=""/>
                                <h4>Overview</h4>
                                <img src={logoutlogo} onClick={logoutx} alt="" style={{marginTop:'30px'}}/>
                                <h4><Link onClick={logout} to='/login'>{uname!==""?'Logout':'Login'}</Link></h4>
                            </div>
                            <div className="col-md-8 col-xs-8" style={{background:'#fff'}}>
                                <ul className="nav navbar-nav" style={{padding:'30px 10px'}}>
                                    <li style={{display:'flex',fontSize:'13px',paddingTop:'10px',paddingBottom:'10px'}}><img src={executivelogo} alt=""/><Link onClick={closeMenu} to='/overview'>Executive Summary</Link></li>
                                    <li style={{display:'flex',fontSize:'13px',paddingTop:'10px',paddingBottom:'10px'}}><img src={servicelogo} alt=""/><Link onClick={closeMenu} to='/service'>Service Breakdown</Link></li>
                                    <li style={{display:'flex',fontSize:'13px',paddingTop:'10px',paddingBottom:'10px'}}><img src={servicebycompanylogo} alt=""/><Link onClick={closeMenu} to='/servicebycompany'>Service Breakdown <br/>by Company</Link></li>
                                    <li style={{display:'flex',fontSize:'13px',paddingTop:'10px',paddingBottom:'10px'}}><img src={categorylogo} alt=""/><Link onClick={closeMenu} to='/category'>Business Category</Link></li>
                                </ul>
                            </div>
                        </div>
                        <ul className="closebarx">
                            <img onClick={closeMenu} src={xlogo} alt=""/>
                        </ul>
                        </div>
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle"
                           onClick={onClick} >
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            </button>
                            <Link className="navbar-brand" to='/overview'><img alt="Quentosity CRM"src="./logo-enhanced.png" /></Link>
                        </div>
                    </div>
                </nav>

        </div>
    )
}

export default Navbar;