import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Tier.css';
const Tier = () => {
    const history = useNavigate();
    const location = useLocation();
    const [tiertable, setProduct] = useState([]);
    const [spostval, setSpostval] = useState('');
    useEffect(() => {
        getTier();
    },[]);
    const { tier } = location.state;
    const { mly } = location.state;
    const { yly } = location.state;
    const getTier = async () => {
        const data = new FormData();
        data.append("username",sessionStorage.getItem("username"));
        data.append("pwd",sessionStorage.getItem("password"));
        let response = "";
        if((spostval==='') || ((spostval.length===1) ||(spostval.length===0))){
            if(spostval.length===1){
                setSpostval('');
            }
            response = await axios.post('https://crmtemp.quentosity.co.nz/gettierdetails.php?status=live&tiergroup='+tier,data);
        }else{
            response = await axios.post('https://crmtemp.quentosity.co.nz/gettierdetails.php?status=live&tiergroup='+tier+'&s='+spostval,data);
        }
        setProduct(response.data);
    }
    const [message, setMessage] = useState('');

    const handleChange = event => {
      setMessage(event.target.value);
      if(event.target.value.length!==0){
        setSpostval(event.target.value);
        getTier();
      }else{
        getTier();
      }
    };
    function goBack(){
        history('/overview');
    }
    return(
        <div className='ui raised tierwrapperbg container' style={{marginTop:'70px'}}>
            <i className="fa fa-arrow-left" aria-hidden="true" onClick={goBack}></i>
            <h3 className='ui header'>Tier {tier.toUpperCase()}</h3>
            <div className='aboverev'>
                <div style={{width:'50%'}}>
                                    <div>MONTHLY REVENUE
                                    </div>
                                    <div style={{fontWeight:'bolder',fontSize:'14px'}}>
                                        {mly}
                                    </div>
                </div>
                <div style={{width:'50%',textAlign:'right'}}>
                                    <div>YEARLY REVENUE
                                    </div>
                                    <div style={{fontWeight:'bolder',fontSize:'14px'}}>
                                        {yly}
                                    </div>
                </div>
            </div>
            <div className='searchwrapper'>
                            <input 
                                type="text" 
                                placeholder='Search by name'
                                onChange={handleChange}
                                value={message}
                                autoComplete="off"
                                className='searchinput'
                                />
                                <i className="fas fa-search"></i>
            </div>
            <div style={{marginTop:'30px'}}>
            {
                tiertable.map((product, index) => (
                    <div key={index} className='tierwrappermain' style={{backgroundColor:'#fff',marginTop:'10px'}}>
                        <div className='tierfirst'>
                            <div style={{width:'90%',fontSize:'14px'}}>
                                { product.agency } 
                            </div>
                            <div style={{color:'#03BA5A'}}>
                                { product.status }
                            </div>
                        </div>
                        <div className='tier2nd'>
                            <div style={{width:'70%'}}>
                                <div>CATEGORY:
                                </div>
                                <div style={{fontWeight:'bolder',fontSize:'12px'}}>
                                { product.category } 
                                </div>
                            </div>
                            <div style={{width:'30%',textAlign:'right'}}>
                                <div>REVENUE:
                                </div>
                                <div style={{fontWeight:'bolder',fontSize:'12px'}}>
                                { product.rev } 
                                </div>
                            </div>
                        </div>
                        <div className='tier3rd' dangerouslySetInnerHTML={{__html:product.services}}>
                            
                        </div>
                    </div>
                )) 
            }
            </div>
        </div>
    )
}

export default Tier;