import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Link, useNavigate} from "react-router-dom";
const Service = () => {
    const history = useNavigate();
    const [mrev, setmrev] = useState('');
    const [yrev, setyrev] = useState('');
    const [tiertable, setProduct] = useState([]);
    useEffect(() => {
        getServiceview();
        getServiceBreakDown();
        var uname = sessionStorage.getItem("username");
        var pword = sessionStorage.getItem("password");
        if(uname==="" && pword===""){
          history("/login");
        }
    },[]);
    const getServiceBreakDown = async () => {
        const datax = new FormData();
        datax.append("username",sessionStorage.getItem("username"));
        datax.append("pwd",sessionStorage.getItem("password"));
        let responses = await axios.post('https://crmtemp.quentosity.co.nz/getservicebreakdown.php',datax);
        setProduct(responses.data);
    }
    const getServiceview = async () => {
        const data = new FormData();
        data.append("username",sessionStorage.getItem("username"));
        data.append("pwd",sessionStorage.getItem("password"));
        const response = await axios.post('https://crmtemp.quentosity.co.nz/getoverview.php',data);
        response.data.forEach(function(r){
            setmrev(r.mrevenue);
            setyrev(r.yrevenue);
        });
    }
    return(
        <div className='ui raised tierwrapperbg container' style={{marginTop:'70px'}}>
            <div className='aboverev'>
                <div style={{width:'50%'}}>
                                    <div>TOTAL MONTHLY VALUE
                                    </div>
                                    <div style={{fontWeight:'bolder',fontSize:'14px'}}>
                                        {mrev}
                                    </div>
                </div>
                <div style={{width:'50%',textAlign:'right'}}>
                                    <div>TOTAL YEARLY VALUE
                                    </div>
                                    <div style={{fontWeight:'bolder',fontSize:'14px'}}>
                                       {yrev}
                                    </div>
                </div>
            </div>
            <div>
                {
                    tiertable.map((product, index) => (
                        <div key={index} className='tierwrapperhalf'>
                            <div className="iconservice">
                                    <img src={product.icon} style={{position: 'relative',marginLeft : '10px',marginTop:'10px'}} />
                            </div>
                            <div className='servicenametitle'>
                                { product.service }
                            </div>
                            <div className='servicexmon'>
                                MONTHLY VALUE
                            </div>
                            <div className='servicenametitlecolor'>
                                { product.monthly }
                            </div>
                            <div className='servicexmon'>
                                YEARLY VALUE
                            </div>
                            <div className='servicenametitlecolor'>
                                { product.yearly }
                            </div>
                            <div className='servicexmon'>
                                NO. OF COMPANIES
                            </div>
                            <div className='servicenametitlecolorx'>
                                { product.number }
                            </div>
                            <Link className="tierlinkservice" to="/breakdown" state={{'tier':product.links,'mly':product.monthly,'yly':product.yearly,'number':product.number,'name':product.service}}>View List</Link>
                        </div>
                    )) 
                }
            </div>
        </div>
    )
}

export default Service;