import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Link, useNavigate} from "react-router-dom";
const ServicebyCompany = () => {
    const history = useNavigate();
    const [qmrev, setqmrev] = useState('');
    const [qyrev, setqyrev] = useState('');
    const [fmrev, setfmrev] = useState('');
    const [fyrev, setfyrev] = useState('');
    const [mmrev, setmmrev] = useState('');
    const [myrev, setmyrev] = useState('');
    const [tiertable, setProduct] = useState([]);
    useEffect(() => {
        getServiceview();
        getServiceBreakDown();
        var uname = sessionStorage.getItem("username");
        var pword = sessionStorage.getItem("password");
        if(uname==="" && pword===""){
          history("/login");
        }
    },[]);
    const getServiceBreakDown = async () => {
        const datax = new FormData();
        datax.append("username",sessionStorage.getItem("username"));
        datax.append("pwd",sessionStorage.getItem("password"));
        let responses = await axios.post('https://crmtemp.quentosity.co.nz/getservicebreakdown.php',datax);
        setProduct(responses.data);
    }
    const getServiceview = async () => {
        const data = new FormData();
        data.append("username",sessionStorage.getItem("username"));
        data.append("pwd",sessionStorage.getItem("password"));
        const response = await axios.post('https://crmtemp.quentosity.co.nz/getoverview.php',data);
        response.data.forEach(function(r){
            setqmrev(r.quentositymrevenue);
            setqyrev(r.quentosityyrevenue);
            setfmrev(r.foundmrevenue);
            setfyrev(r.foundyrevenue);
            setmmrev(r.mantismrevenue);
            setmyrev(r.mantisyrevenue);
        });
    }
    return(
        <div className='ui raised tierwrapperbg container' style={{marginTop:'70px'}}>
            <div className='aboverev'>
                <div style={{width:'50%'}}>
                                    <div>TOTAL QUENTOSITY <br/>MONTHLY VALUE
                                    </div>
                                    <div style={{fontWeight:'bolder',fontSize:'14px'}}>
                                        {qmrev}
                                    </div>
                </div>
                <div style={{width:'50%',textAlign:'right'}}>
                                    <div>TOTAL QUENTOSITY <br/> YEARLY VALUE
                                    </div>
                                    <div style={{fontWeight:'bolder',fontSize:'14px'}}>
                                       {qyrev}
                                    </div>
                </div>
            </div>
            <div className='aboverev'>
                <div style={{width:'50%'}}>
                                    <div>TOTAL FOUND <br/>MONTHLY VALUE
                                    </div>
                                    <div style={{fontWeight:'bolder',fontSize:'14px'}}>
                                        {fmrev}
                                    </div>
                </div>
                <div style={{width:'50%',textAlign:'right'}}>
                                    <div>TOTAL FOUND <br/> YEARLY VALUE
                                    </div>
                                    <div style={{fontWeight:'bolder',fontSize:'14px'}}>
                                       {fyrev}
                                    </div>
                </div>
            </div>
            <div className='aboverev'>
                <div style={{width:'50%'}}>
                                    <div>TOTAL MANTIS <br/>MONTHLY VALUE
                                    </div>
                                    <div style={{fontWeight:'bolder',fontSize:'14px'}}>
                                        {mmrev}
                                    </div>
                </div>
                <div style={{width:'50%',textAlign:'right'}}>
                                    <div>TOTAL MANTIS <br/> YEARLY VALUE
                                    </div>
                                    <div style={{fontWeight:'bolder',fontSize:'14px'}}>
                                       {myrev}
                                    </div>
                </div>
            </div>
            <div>
                {
                    tiertable.map((product, index) => (
                        <div key={index} className='tierwrapperwhole'>
                            <div className="iconservice">
                                    <img src={product.icon} style={{position: 'relative',marginLeft : '10px',marginTop:'10px'}} />
                            </div>
                            <div className='servicenametitle'>
                                { product.servicefull }
                            </div>
                            <div className='servicexmon' style={{display:'flex'}}><div style={{width:'50%'}} >QUENTOSITY MONTHLY</div><div style={{width:'50%',textAlign:'right'}}>QUENTOSITY YEARLY</div></div>
                            <div className='servicenametitlecolor' style={{display:'flex'}}><div style={{width:'50%'}}>{ product.qmonthly }</div><div style={{width:'50%',textAlign:'right'}}>{ product.qyearly }</div></div>
                            <div className='servicexmon' style={{display:'flex'}}><div style={{width:'50%'}} >FOUND MONTHLY</div><div style={{width:'50%',textAlign:'right'}}>FOUND YEARLY</div></div>
                            <div className='servicenametitlecolor' style={{display:'flex'}}><div style={{width:'50%'}}>{ product.fmonthly }</div><div style={{width:'50%',textAlign:'right'}}>{ product.fyearly }</div></div>
                            <div className='servicexmon' style={{display:'flex'}}><div style={{width:'50%'}} >MANTIS MONTHLY</div><div style={{width:'50%',textAlign:'right'}}>MANTIS YEARLY</div></div>
                            <div className='servicenametitlecolor' style={{display:'flex'}}><div style={{width:'50%'}}>{ product.mmonthly }</div><div style={{width:'50%',textAlign:'right'}}>{ product.myearly }</div></div>
                            <Link className="tierlinkservice" to="/breakdown" state={{'tier':product.links,'mly':product.monthly,'yly':product.yearly,'number':product.number,'name':product.service}}>View List</Link>
                        </div>
                    )) 
                }
            </div>
        </div>
    )
}

export default ServicebyCompany;