import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Link, useNavigate} from "react-router-dom";
import Highcharts from 'highcharts';
import $ from 'jquery';
const Category = () => {
    const history = useNavigate();
    const [tiertable, setProduct] = useState([]);
    let getServiceview = async () => {
      const data = new FormData();
      data.append("username",sessionStorage.getItem("username"));
      data.append("pwd",sessionStorage.getItem("password"));
      const response = await axios.post('https://crmtemp.quentosity.co.nz/getCategoryHigh.php',data);
       let r = response.data[0];
       LoadHigh(r.corp,r.sme,r.sole,r.not,r.clubs,r.gov,r.assoc,r.school,r.fran,r.coop);
    }
    const getServiceBreakDown = async () => {
        const datax = new FormData();
        datax.append("username",sessionStorage.getItem("username"));
        datax.append("pwd",sessionStorage.getItem("password"));
        let responses = await axios.post('https://crmtemp.quentosity.co.nz/getCategorymain.php',datax);
        setProduct(responses.data);
    }
    useEffect(() => {
        getServiceBreakDown();
        getServiceview();
        var uname = sessionStorage.getItem("username");
        var pword = sessionStorage.getItem("password");
        if(uname==="" && pword===""){
          history("/login");
        }
    },[]);
    async function LoadHigh(corp,sme,sole,not,clubs,govx,assoc,school,fran,coop){
      Highcharts.setOptions({
          colors: ['#40CEFF', '#A9E056', '#601A83', '#F74B3E', '#DE16AC', '#FF7639', '#2EC49C', '#000000','#FFC20A','#5381DE']
         });
     Highcharts.chart('piedonut-container', {
       chart: {
         plotBackgroundColor: null,
         plotBorderWidth: null,
         plotShadow: false,
         type: 'pie'
       },
       title: {
         text: ''
       },
       tooltip: {
         pointFormat: '<b>{point.percentage:.1f}%</b>'
       },
       accessibility: {
         point: {
           valueSuffix: '%'
         }
       },
       plotOptions: {
         pie: {
                 dataLabels: {
                     enabled: true,
                     distance: -50,
                     format:'{point.percentage:.0f}%',
                     style: {
                         fontWeight: 'bold',
                         color: 'white'
                     }
                 },
             showInLegend: true,
         }
       },
         
         
         legend: {
         enabled: true,
         y: 0,
         align: 'right',
         verticalAlign: 'bottom',
         margin: 0,
         width: '100%',
         borderWidth: 0,
         padding: 15,
         itemMarginTop: 15,
         itemMarginBottom: 0,
         itemStyle: {
                 color: '#000',
                 fontFamily: 'Roboto',
                 
         
         }
     },
         

         
       series: [{
         colorByPoint: true,
         innerSize: '55%',
         data: [{
           name: 'Corporate',
           y: corp
         }, {
           name: 'SME',
           y: sme
         }, {
           name: 'Sole Traders',
           y: sole
         }, {
           name: 'CO-OP',
           y: coop
         }, {
           name: 'Not For Profit',
           y: not
         }, {
           name: 'Clubs',
           y: clubs
         }, {
           name: 'GOVT',
           y: govx
         }, {
           name: 'Associations',
           y: assoc
         }, {
           name: 'Schools',
           y: school
         }, {
           name: 'Franchise',
           y: fran
         }]
       }]
     });
    }
    $('.highcharts-credits').html('');
    return(
        <div>
        <div className="piechart pr-0">
            <figure className="highcharts-figure">
                <div id="piedonut-container"></div>
            </figure>
        </div>
            <div className='ui raised tierwrapperbg container'>
                {
                    tiertable.map((product, index) => (
                        <div key={index} className='tierwrapperhalf'>
                            <div className='servicenametitle'>
                                { product.category }
                            </div>
                            <div className='servicexmon'>
                                MONTHLY VALUE
                            </div>
                            <div className='servicenametitlecolorx'>
                                { product.monthly }
                            </div>
                            <div className='servicexmon'>
                                YEARLY VALUE
                            </div>
                            <div className='servicenametitlecolorx'>
                                { product.yearly }
                            </div>
                            <div className='servicexmon'>
                                NO. OF COMPANIES
                            </div>
                            <div className='servicenametitlecolorx'>
                                { product.number }
                            </div>
                            <Link className="tierlinkservice" to="/catdetails" state={{'tier':product.link,'mly':product.monthly,'yly':product.yearly,'number':product.number,'name':product.category}}>View List</Link>
                        </div>
                    )) 
                }
            </div>
        </div>
    )
}

export default Category;