import React, {useState,useRef} from 'react';
import axios from 'axios';
import {Link, useNavigate } from 'react-router-dom';
import "./Login.css";
import { useAppContext } from "../lib/contextLib";
const Login = () => {
    const history = useNavigate();
    const [showmenu,setmenu] = useState(false);
    sessionStorage.setItem("username","");
    sessionStorage.setItem("password","");
    const inputRef = useRef(null);
    async function Login(event){
        event.preventDefault();
        var data = new FormData(event.target);
        axios.post('https://crmtemp.quentosity.co.nz/getoverview.php',data)
        .then(function(r){
            if(r.data==='Unauthorized'){
                alert("Unauthorized");
            }else{
                sessionStorage.setItem("username",data.get("username"));
                sessionStorage.setItem("password",data.get("pwd"));
                userHasAuthenticated(true);
                history("/overview");            }
        })
    }
    const { userHasAuthenticated } = useAppContext();
    function resetpassword(){
        setmenu(true);
    }
    function sendresetpassword(){
        var data = new FormData();
        data.append("email",inputRef.current.value);
        console.log(data);
        axios.post('https://crmtemp.quentosity.co.nz/resetpassword.php',data)
        .then(function(r){
            console.log(r);
            if(r.data===0){
                setmenu(false);
                alert('Reset password sent');
            }
            else{
                setmenu(true);
                alert('Email address not found in the system');
            }
        })
    }
    return(
        <div className='ui raised very padded text container segment' style={{marginTop:'10px'}}>
            <div className='loginlogo'><img alt="Quentosity CRM" src="./logo2.png" /></div>
            <div style={showmenu?{display:'none'}:{display:'block'}}>
                <form onSubmit={Login}>
                    Username
                    <input type='text' placeholder='Enter Username or Email Address' name='username' className='loginusername'></input>
                    Password
                    <input type='password' placeholder='Enter Password' name='pwd' className='loginpassword'></input>
                    <div className='rememberme'>Remember me <input type="checkbox"></input></div>
                    <button type='submit' className='loginbtn'>Signin</button>
                </form>
            
                <Link onClick={resetpassword} className="forgotpassword">Forgot Password?</Link>
            </div>
            <div style={showmenu?{display:'block'}:{display:'none'}}>
                    <input type='text' ref={inputRef} name='email' placeholder='Enter Email Address' className='loginusername'></input>
                    <button type='button' onClick={sendresetpassword} className='loginbtn'>Get New Password</button>
            </div>
        </div>
        
    )
}
export default Login;
